import { IonButton, IonIcon } from '@ionic/react';
import { arrowBackOutline, homeOutline } from 'ionicons/icons';
import { Page } from 'components/shared/Page';

import 'pages/shared/PageNotFound.css';
import { useHistory } from 'react-router';

export const PageNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <Page className="notFound" title="404">
      <div className="notFoundContainer ion-text-center">
        <h1 className="ion-margin-bottom">404</h1>
        <p className="ion-margin-bottom">
          Whoops, the page you're looking for couldn't be found...
        </p>
        <IonButton
          className="ion-margin-horizontal"
          fill="outline"
          onClick={() => history.goBack()}
        >
          <IonIcon slot="start" icon={arrowBackOutline}></IonIcon>
          Go Back
        </IonButton>
        <IonButton
          routerLink="/"
          routerDirection="root"
          className="ion-margin-horizontal"
          fill="outline"
        >
          <IonIcon slot="start" icon={homeOutline} />
          Home
        </IonButton>
      </div>
    </Page>
  );
};
