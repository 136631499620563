import { Route } from 'react-router-dom';

const RouteGuest = ({ component: Component, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={(props) =>
        <Component {...props} />
      }
    />
  );
};

export default RouteGuest;
