import App from 'App';
import { createRoot } from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';
import reportWebVitals from 'reportWebVitals';
// import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container!);

declare global {
  interface Window {
    InlineManual: any;
  }
}

root.render(
    <div>
      <App />
    </div>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
