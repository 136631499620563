import {
  IonApp,
  IonCol,
  IonRow,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import 'styles/variables.css';

/* KendoReact Theme */
import '@progress/kendo-theme-default/dist/all.css';

/* Inline Manual stylesheet*/
import 'styles/inline-manual.css';

/* Custom stylesheet */
import 'styles/App.css';

import LogoTrulla from 'components/shared/LogoTrulla';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import Routes from 'routes/Routes';

import { AxiosError } from 'axios';

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false,
  innerHTMLTemplatesEnabled: false,
});

const App: React.FC = () => {
  
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  return (
    <IonApp>
      <IonReactRouter>
      <IonRow style={{ marginTop: '26px' }}>
              <IonCol className="ion-text-center">
                <LogoTrulla />
              </IonCol>
            </IonRow>
            <Routes />
      </IonReactRouter>
      {/* Global toast messages */}
      

      <Tooltip id="global-tooltip">{/* filled dynamically */}</Tooltip>
    </IonApp>
  );
};

export default App;
