import { IonRouterLink } from '@ionic/react';
import 'components/shared/LogoSupplier.css';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';

// TODO: if logo is missing (either supplier has no logo, or there was an error loading the logo), just show the supplier name as plain text
const LogoSupplier: React.FC<{
  id?: number;
  src?: string;
  alt?: string;
  routerLink?: string;
  onClick?: any;
  className?: string;
  style?: any;
  title?: string;
}> = ({ id, src, alt, routerLink, onClick, className, style, title }) => {
  const Logo: React.FC = () => {
    const allSuppliers: Supplier[] | null = [];
    const [supplier, setSupplier] = useState<Supplier>();
    useEffect(() => {
      if (id && allSuppliers) {
        setSupplier(
          allSuppliers.find((supplier) => supplier.supplier_id === id)
        );
      }
    }, [id]);

    return (
      <>
        {!id && (
          <img
            className={`supplierLogo ${onClick ? 'clickable' : ''} ${
              className ? className : ''
            }`}
            src={src}
            alt={alt}
            onClick={() => (onClick ? onClick() : null)}
            style={style || null}
            title={title || undefined}
          />
        )}
        {id && supplier && (
          <img
            className={`supplierLogo ${onClick ? 'clickable' : ''} ${
              className ? className : ''
            }`}
            src={supplier.logo}
            alt={alt}
            onClick={() => (onClick ? onClick() : null)}
            style={style || null}
            title={title || undefined}
          />
        )}
      </>
    );
  };

  return (
    <>
      {routerLink && (
        <IonRouterLink routerLink={routerLink}>
          <Logo />
        </IonRouterLink>
      )}
      {!routerLink && <Logo />}
    </>
  );
};

export default LogoSupplier;
