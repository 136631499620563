import 'components/shared/Divider.css';

const Divider: React.FC<{ text?: any; className?: string }> = ({
  text,
  className,
}) => {
  return (
    <div className={`divider ${className ? className : ''}`}>
      {text && <p>{text}</p>}
    </div>
  );
};

export default Divider;
