import { IonButton, IonIcon } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

const ButtonBackToLogin: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  return (
    <IonButton
      fill="clear"
      routerLink="/"
      routerDirection="back"
      disabled={disabled}
    >
      <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
      Back
    </IonButton>
  );
};
export default ButtonBackToLogin;
