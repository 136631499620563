import {
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import 'components/shared/Page.css';

export const Page: React.FC<{
  children: any;
  footer?: any;
  className?: string;
  title?: string;
  updateDocTitle?: boolean;
}> = ({ children, footer, className, title, updateDocTitle = true }) => {
  useIonViewWillEnter(() => {
    if (title && updateDocTitle) {
      document.title = `${title} | Trulla Direct`;
    } else {
      document.title = 'Trulla Direct';
    }
  });

  return (
    <IonPage className={`pageLayout ${className ? className : ''}`}>
      <IonContent fullscreen className="ion-padding">
        <IonGrid>{children}</IonGrid>
      </IonContent>

      {footer && (
        <IonFooter className="pageActions">
          <IonToolbar>{footer}</IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};
