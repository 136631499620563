import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react';

import 'components/shared/CardCentered.css';

const CardCentered: React.FC<{
  children: any;
  title: string;
}> = ({ children, title }) => {
  return (
    <IonCard className="centerCard">
      <IonCardTitle className="ion-text-center ion-padding ion-margin-bottom">
        {title}
      </IonCardTitle>
      <IonCardContent>
        <div className="formContainer">{children}</div>
      </IonCardContent>
    </IonCard>
  );
};

export default CardCentered;
