import { faDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import CardStat from 'components/shared/CardStat';
import LogoSupplier from 'components/shared/LogoSupplier';
import LogoTrulla from 'components/shared/LogoTrulla';
import ModalTerms from 'components/shared/ModalTerms';
import { cashOutline, clipboardOutline, openOutline } from 'ionicons/icons';
import 'pages/shared/PageLanding.css';
import { Fragment, useState } from 'react';
import { toUsd } from 'utils';

export const PageLanding: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const router = useIonRouter();

  const currentYear = new Date().getFullYear();

  useIonViewWillEnter(() => {
    document.title = `Trulla Direct`;
  });

  const onClickLogin = () => {
    window.location.href = process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3002';
  };

  const showTerms = () => {
    setTermsOpen(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (email) {
        router.push(`/register?email=${email}`);
      }
    }
  };

  const IconBlock: React.FC<{
    title: string;
    message: string;
    icon: any;
  }> = ({ title, message, icon }) => {
    return (
      <>
        <div className="landingIconContainer">
          <div className="landingIconContainerInner">{icon}</div>
        </div>
        <h4>{title}</h4>
        <p>{message}</p>
      </>
    );
  };

  const faqs = [
    {
      question: 'Does Trulla Direct cost money?',
      answer:
        'Trulla Direct is free for pharmacy buyers. There are no hidden fees or charges.',
    },
    {
      question: 'How many suppliers are on Trulla Direct?',
      answer:
        'Trulla Direct is launching with Sandoz as its first supplier on the platform. We are currently in discussions with many more suppliers and they will be added over time.',
    },
    {
      question: 'How are the orders submitted to suppliers?',
      answer:
        'All orders are submitted to the suppliers via Electronic Data Interchange (EDI).',
    },
    {
      question: 'Do I have to have a direct account with the supplier?',
      answer:
        'Yes. To place any order to a supplier, you must have a direct account with that supplier. The Trulla Direct application helps facilitate and streamline that process.',
    },
    {
      question: 'Is Trulla Direct different than other Trulla products?',
      answer:
        "Trulla Direct is built to support ordering direct to manufacturers and 503B vendors. Trulla Procurement is built to support all ordering to all suppliers including wholesalers. Contact us if you'd like to learn more about either platform.",
    },
  ];

  return (
    <IonPage id="landingPage">
      <IonHeader>
        <div className="container">
          <IonToolbar>
            <div slot="start" className="d-flex ion-align-center">
              <LogoTrulla style={{ height: '42px' }} />
            </div>
            <div slot="end" className="d-flex ion-align-center">
              <IonButton fill="clear" onClick={onClickLogin} disabled={loading}>
                Log in
                {loading && (
                  <IonSpinner
                    slot="end"
                    name="crescent"
                    style={{ marginLeft: '5px' }}
                  ></IonSpinner>
                )}
              </IonButton>
              <IonButton routerLink="/register">Get started</IonButton>
            </div>
          </IonToolbar>
        </div>
      </IonHeader>
      <IonContent>
        {/* DIRECT ORDERING */}
        <IonGrid>
          <IonRow className="container ion-align-items-center padding-right">
            {/* LEFT */}
            <IonCol size="12" sizeMd="6" className="ion-padding-horizontal">
              <div>
                <h1>Direct Ordering for Pharmacy Buyers</h1>
                <p className="subheader" style={{ maxWidth: '530px' }}>
                  An innovative online pharmacy ordering platform designed to
                  improve and simplify the direct to manufacturer buying process
                  for the medications not typically available through the
                  pharmacy wholesaler.
                </p>
              </div>
              <div>
                <IonItem lines="none" className="ion-no-padding">
                  <IonInput
                    placeholder="Enter your email"
                    className="rounded"
                    value={email}
                    onKeyDown={handleKeyDown}
                    onIonChange={(e) => setEmail(e.detail.value as string)}
                  />
                  <IonNote slot="helper">
                    We care about your data in our{' '}
                    <a
                      href="/assets/doc/SM_IT_Privacy_Declaration_v1.00.pdf"
                      target="_blank"
                    >
                      Privacy Policy. <IonIcon icon={openOutline} />
                    </a>
                  </IonNote>
                  <div slot="end">
                    <IonButton
                      size="default"
                      routerLink={`/register${
                        !!email ? '?email=' + email : ''
                      }`}
                    >
                      Get started
                    </IonButton>
                  </div>
                </IonItem>
              </div>
            </IonCol>

            {/* RIGHT */}
            <IonCol size="12" sizeMd="6" className="ion-padding-horizontal">
              <div id="heroContainer">
                <IonImg className="pattern" src="assets/landing/pattern.png" />
                <IonImg className="hero" src="assets/landing/hero_image.png" />
                {/* <div className="orderStat spend">
                  <CardStat
                    color="success"
                    title="spend"
                    stat={toUsd(3651)}
                    statTitle="Last 30 Days"
                    icon={cashOutline}
                  />
                </div> */}
                <div className="orderStat orders">
                  <CardStat
                    color="success"
                    title="Orders"
                    stat={47}
                    statTitle="Last 30 Days"
                    icon={clipboardOutline}
                  />
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* VALUE PROP */}
        <IonGrid style={{ paddingBottom: '0', paddingTop: '0' }}>
          <IonRow>
            <IonCol size="12" sizeLg="7" className="ion-padding-horizontal">
              <h4>Benefits</h4>
              <h2>Value to Pharmacy Buyers</h2>
              <p className="subheader">
                Quickly and easily order medications directly from
                manufacturers. Our complementary solution helps you manage the
                last mile of orders that do not go through your primary
                wholesaler.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* SPLIT */}
        <IonGrid>
          <IonRow className="ion-align-items-center">
            {/* LEFT */}
            <IonCol size="12" sizeMd="6" className="ion-padding-horizontal">
              <IonRow>
                <IonCol>
                  <IconBlock
                    icon={
                      <img
                        src="assets/landing/cart.svg"
                        alt=""
                        style={{ marginRight: '3px', marginTop: '2px' }}
                      />
                    }
                    title="Single Portal"
                    message="The buyer's direct orders to multiple suppliers can be placed
                      in a single shopping cart."
                  />
                </IonCol>
                <IonCol>
                  <IconBlock
                    icon={
                      <img
                        src="assets/landing/simple.svg"
                        alt=""
                        style={{ marginTop: '2px' }}
                      />
                    }
                    title="Simplicity"
                    message="So easy that buyers can register and place their first direct order in just minutes."
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IconBlock
                    icon={<img src="assets/landing/inventory.svg" alt="" />}
                    title="Your Contracts"
                    message="Integrates with suppliers to give access to the prices and contracts specific to your organization."
                  />
                </IonCol>
                <IonCol>
                  <IconBlock
                    icon={<FontAwesomeIcon icon={faDollar} />}
                    title="Free for Buyers"
                    message="Trulla Direct is free for pharmacy buyers. There are no hidden fees or charges."
                  />
                </IonCol>
              </IonRow>
            </IonCol>

            {/* RIGHT */}
            <IonCol
              style={{ overflow: 'hidden', position: 'relative' }}
              size="12"
              sizeMd="6"
              className="ion-padding-horizontal"
            >
              <div id="laptopContainer">
                <div
                  className="laptop"
                  style={{
                    backgroundImage: 'url(assets/landing/laptop.png)',
                  }}
                >
                  <div
                    className="screen"
                    style={{
                      backgroundImage:
                        'url(assets/landing/screenshot_dashboard.png)',
                    }}
                  ></div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* FEATURED SUPPLIERS */}
        <IonGrid className="dark-bg">
          <IonRow className="ion-justify-content-center ion-text-center">
            <IonCol size="12">
              <h4>Featured Direct Suppliers</h4>
            </IonCol>
            <IonCol size="12" sizeSm="6" sizeMd="4" className="ion-text-center">
              <LogoSupplier
                src="assets/suppliers/sandoz-logo-light.png"
                alt="Sandoz"
                style={{ maxWidth: 'none', height: '40px' }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* FAQ */}
        <IonGrid>
          <IonRow className="ion-text-center" style={{ maxWidth: '1216px' }}>
            <IonCol
              size="12"
              className="ion-text-center ion-padding-horizontal"
            >
              <h2>Frequently asked questions</h2>
              <p>Everything you need to know about Trulla Direct.</p>
            </IonCol>
            {/* FAQ row */}
            <IonCol size="12" className="ion-padding-horizontal">
              <IonAccordionGroup id="faqs">
                {faqs.map((faq) => (
                  <Fragment key={faq.question}>
                    <IonAccordion value={faq.question}>
                      <IonItem slot="header" color="white">
                        <IonLabel>{faq.question}</IonLabel>
                      </IonItem>
                      <div className="ion-padding ion-text-left" slot="content">
                        <p className="ion-padding-start">{faq.answer}</p>
                      </div>
                    </IonAccordion>
                  </Fragment>
                ))}
              </IonAccordionGroup>
            </IonCol>
            {/* <div
              className="fullWidth light-bg"
              style={{
                borderRadius: '10px',
                marginTop: '64px',
                padding: '32px',
              }}
            >
              <h3>Still have questions?</h3>
              <p style={{ marginTop: '0' }}>
                Can't find the answer you're looking for? Please chat to our
                friendly team.
              </p>
              <IonButton>Get in touch</IonButton>
            </div> */}
          </IonRow>
        </IonGrid>

        {/* REVIEWS */}
        <IonGrid className="dark-bg">
          <IonRow>
            <IonCol className="ion-text-center">{/* <p>Reviews</p> */}</IonCol>
          </IonRow>
        </IonGrid>

        {/* TRY TRULLA */}
        <IonGrid>
          <IonRow className="ion-text-center">
            <IonCol size="12" className="ion-padding-horizontal">
              <h2>Get started today!</h2>
              <p className="subheader">
                Start buying direct from multiple suppliers with a single
                shopping cart.
              </p>
            </IonCol>
            <div
              style={{ maxWidth: '768px', margin: '0 auto' }}
              className="ion-padding-horizontal"
            >
              <IonCol size="12">
                <IonItem
                  lines="none"
                  style={{
                    '--padding-start': '2px',
                    '--inner-padding-end': '3px',
                  }}
                >
                  {/* <IonLabel position="stacked" className="margin-left-sm">
                      Email
                    </IonLabel> */}
                  <IonInput
                    className="rounded"
                    placeholder="Enter your email"
                    value={email}
                    onKeyDown={handleKeyDown}
                    onIonChange={(e) => setEmail(e.detail.value as string)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="12" className="ion-padding-horizontal">
                <IonButton
                  expand="full"
                  shape="round"
                  routerLink={`/register${!!email ? '?email=' + email : ''}`}
                >
                  Create Account
                </IonButton>
              </IonCol>
              <IonCol size="12" className="ion-padding-horizontal">
                <p className="subtitle">
                  By clicking "Create account", you agree to the Trulla{' '}
                  <a onClick={showTerms}>TOS</a> and{' '}
                  <a
                    href="/assets/doc/SM_IT_Privacy_Declaration_v1.00.pdf"
                    target="_blank"
                  >
                    Privacy Policy. <IonIcon icon={openOutline} />
                  </a>
                </p>
              </IonCol>
              <IonCol size="12" className="ion-padding-horizontal">
                <p style={{ fontSize: '14px' }}>
                  Already have an account?{' '}
                  <IonRouterLink onClick={onClickLogin}>Log in</IonRouterLink>
                </p>
              </IonCol>
            </div>
          </IonRow>
        </IonGrid>

        {/* FOOTER */}
        <IonGrid className="dark-bg">
          <IonRow className="ion-text-center ion-padding-horizontal">
            <IonCol size="12">
              <LogoTrulla style={{ height: '42px' }} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
          </IonRow>
          <IonRow className="copyrightRow ion-padding-horizontal">
            <IonCol size="12" sizeSm="6">
              <p className="copyright">
                &copy; {currentYear} Trulla. All rights reserved.
              </p>
            </IonCol>
            <IonCol size="12" sizeSm="6">
              <ul>
                <li>
                  <a onClick={showTerms}>Terms</a>
                </li>
                <li>
                  <a
                    href="/assets/doc/SM_IT_Privacy_Declaration_v1.00.pdf"
                    target="_blank"
                  >
                    Privacy <IonIcon icon={openOutline} />
                  </a>
                </li>
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <ModalTerms isOpen={termsOpen} onDismiss={() => setTermsOpen(false)} />
    </IonPage>
  );
};
