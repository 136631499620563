import {
  IonCol,
  IonGrid,
  IonicSlides,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import CardCentered from 'components/shared/CardCentered';
import { Page } from 'components/shared/Page';
import { useEffect, useState } from 'react';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import 'pages/shared/PageRegister/PageRegister.css';
import 'swiper/css';

import { useDispatch } from 'react-redux';
import { Step1Form } from './Step1Form';
import { Step2Form } from './Step2Form';
// import { setToast } from 'redux/appReducer';
import axios from 'axios';
import { useLocation } from 'react-router';

export const PageRegister: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [step1FormValid, setStep1FormValid] = useState(false);

  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [locationName, setLocationName] = useState<string | null>(null);
  const [addressLine1, setAddressLine1] = useState<string | null>(null);
  const [addressLine2, setAddressLine2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [zip, setZip] = useState<string | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [step2FormValid, setStep2FormValid] = useState(false);

  useEffect(() => {
    const email = params.get('email') || null;
    if (email) {
      setEmail(email);
    }
  }, []);

  const slidePrev = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const slideNext = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  const onActiveIndexChange = (e?: any) => {
    const index = swiperInstance?.activeIndex;
    setActiveIndex(index);
  };

  const onSubmitStep1Form = () => {
    if (step1FormValid) {
      slideNext();
    } else {
      // dispatch(
      //   // setToast({
      //   //   message: 'Please fill out all required fields.',
      //   //   color: 'danger',
      //   // })
      // );
    }
  };

  const onSubmitStep2Form = () => {
    if (step2FormValid) {
      slideNext();
    } else {
      // dispatch(
      //   // setToast({
      //   //   message: 'Please fill out all required fields.',
      //   //   color: 'danger',
      //   // })
      // );
    }
    register();
  };

  const register = () => {
    const formData = {
      organizationName: organizationName,
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: email,
      phone1: phone,
      phone2: null,
      userOnboardUrl: 'https://trulladirectdevwebapi.azurewebsites.net/', // make dynamic
      locationName: locationName,
      street: addressLine2 ? `${addressLine1}, ${addressLine2}` : addressLine1,
      city: city,
      state: state,
      zip: zip,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_ONBOARD_API,
    };

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_ONBOARD_API}/onboardrequests`,
        formData
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          // dispatch(
          //   setToast({
          //     header: 'Successfully submitted!',
          //     message:
          //       'Our team will review the information to approve your account. You should expect a response within 1 business day',
          //     duration: 20000,
          //     buttons: [
          //       {
          //         text: 'Ok',
          //         role: 'cancel',
          //       },
          //     ],
          //   })
          // );
          router.push('/', 'back', 'pop');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // dispatch(
        //   setToast({
        //     header: 'Error',
        //     color: 'danger',
        //     message:
        //       'There was an error submitting your request. Please wait a moment and try again.',
        //     duration: 3000,
        //   })
        // );
      });
  };

  return (
    <Page className="guest" title="Register">
      <IonRow>
        <IonCol>
          <CardCentered title="Create Account">
            <IonRow>
              <IonCol>
                <div className="slideHeader">
                  <div
                    className={`step active ${
                      activeIndex === 1 ? 'complete' : ''
                    }`}
                  >
                    <div className="number">1</div>
                    <p>About You</p>
                  </div>
                  <div className={`step ${activeIndex === 1 ? 'active' : ''}`}>
                    <div className="number">2</div>
                    <p>Your Facility</p>
                  </div>
                </div>
              </IonCol>
            </IonRow>

            <Swiper
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              modules={[IonicSlides]}
              initialSlide={0}
              speed={400}
              allowTouchMove={false}
              onActiveIndexChange={() => onActiveIndexChange()}
            >
              {/* SLIDE 1 */}
              <SwiperSlide>
                <IonGrid>
                  <Step1Form
                    loading={loading}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    onSubmit={onSubmitStep1Form}
                    formValid={step1FormValid}
                    setFormValid={setStep1FormValid}
                  />
                </IonGrid>
              </SwiperSlide>

              {/* SLIDE 2 */}
              <SwiperSlide>
                {activeIndex === 1 && (
                  <IonGrid>
                    <Step2Form
                      loading={loading}
                      slidePrev={slidePrev}
                      organizationName={organizationName}
                      setOrganizationName={setOrganizationName}
                      locationName={locationName}
                      setLocationName={setLocationName}
                      addressLine1={addressLine1}
                      setAddressLine1={setAddressLine1}
                      addressLine2={addressLine2}
                      setAddressLine2={setAddressLine2}
                      city={city}
                      setCity={setCity}
                      state={state}
                      setState={setState}
                      zip={zip}
                      setZip={setZip}
                      termsAccepted={termsAccepted}
                      setTermsAccepted={setTermsAccepted}
                      onSubmit={onSubmitStep2Form}
                      formValid={step2FormValid}
                      setFormValid={setStep2FormValid}
                    />
                  </IonGrid>
                )}
              </SwiperSlide>
            </Swiper>
          </CardCentered>
        </IonCol>
      </IonRow>
    </Page>
  );
};
