import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSkeletonText,
  IonText,
} from '@ionic/react';

import 'components/shared/CardStat.css';
import { useEffect, useState } from 'react';

const CardStat: React.FC<{
  title: string;
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'medium'
    | 'light'
    | 'dark';
  icon: any;
  stat: number | string;
  statTitle?: string;
  percent?: number;
  percentTitle?: string;
  loading?: boolean;
  selected?: boolean;
  onClick?: any;
  iconClass?: string;
  tooltip?: any;
  routerLink?: string;
  routerDirection?: 'root' | 'forward' | 'back' | 'none';
}> = ({
  title,
  color,
  icon,
  stat,
  statTitle,
  percent,
  percentTitle,
  loading,
  selected = false,
  onClick,
  iconClass,
  tooltip,
  routerLink,
  routerDirection,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (percent !== undefined) {
      setTimeout(() => {
        setProgress(percent);
      }, 10);
    }
  }, [percent]);

  return (
    <IonCard
      button={onClick ? true : false}
      className={`statCard ${selected && 'selected'}`}
      data-color={color}
      onClick={onClick}
      routerLink={routerLink}
      routerDirection={routerDirection}
      data-tooltip-id={tooltip ? 'global-tooltip' : undefined}
      data-tooltip-html={tooltip ? tooltip : <span>Hello</span>}
      data-tooltip-place={tooltip ? 'bottom' : undefined}
    >
      <IonCardHeader>
        <IonCardSubtitle>{title}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow className="ion-align-items-center ion-justify-content-end statNumberRow">
          {(typeof icon === 'string' && (
            <IonIcon icon={icon} color={color}></IonIcon>
          )) || (
            <IonText color={color} style={{ lineHeight: 1 }}>
              <FontAwesomeIcon icon={icon} className={iconClass || undefined} />
            </IonText>
          )}
          <p className="statNumber">
            {(loading && (
              <IonSkeletonText
                animated={true}
                style={{ width: '40px', height: 'var(--font-size-large)' }}
              />
            )) ||
              stat}
          </p>
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-end statTitleRow">
          <IonText color={color} className="font-size-small">
            {statTitle}
          </IonText>
        </IonRow>
        {percent !== undefined && (
          <>
            <IonText color={color}>
              {(loading && (
                <IonSkeletonText
                  animated={true}
                  style={{ width: '50%', height: 'var(--font-size-default)' }}
                />
              )) || (
                <p>
                  {percent * 100}%{' '}
                  {percentTitle && (
                    <span style={{ fontSize: '80%' }}>of {percentTitle}</span>
                  )}
                </p>
              )}
            </IonText>
            <IonProgressBar
              value={progress}
              color={color}
              type={loading ? 'indeterminate' : 'determinate'}
            />
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default CardStat;
