import RouteGuest from 'routes/RouteGuest';
import { IonRouterOutlet } from '@ionic/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { PageLanding } from 'pages/shared/PageLanding';
import { PageRegister } from 'pages/shared/PageRegister/PageRegister';
import { PageNotFound } from 'pages/shared/PageNotFound';

const Routes: React.FC = () => {

  
  const location = useLocation();

  useEffect(() => {
    if (window.InlineManual !== undefined) {
      window.InlineManual('update');
    }
  }, [location.pathname]);

  return (
    <IonRouterOutlet animated={true}>   

      <RouteGuest path="/" component={PageLanding} exact={true} />
      <RouteGuest path="/register" component={PageRegister} exact={true} />

      {/* Catch all route */}
      <Route
        render={(props) => {
          return <PageNotFound />;
        }}
      ></Route>
    </IonRouterOutlet>
  );
};

export default Routes;
